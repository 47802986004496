import React from "react"
import PropTypes from "prop-types"
import { navigate, Link, useStaticQuery } from "gatsby"
import { updateProduct, removeProduct } from "../actions/index"
import { graphql } from "gatsby"

import Img from "gatsby-image"
import PrevisuPreCoupe from "./../components/previsuPreCoupe"
import PrevisuCadreComplet from "./../components/previsuCadreComplet"
import ImgBaguette from "./imgBaguette"
import ImgPasse from "./imgPasse"
import { useDispatch, useSelector } from "react-redux"

export const CartProducts = props => {
  const data = useStaticQuery(graphql`
    query imageForPanier {
      allMysqlNewProduit(filter: { isDoublo: { eq: 0 } }) {
        edges {
          node {
            id
            ba_id
            ba_descriptif
            ba_photo_longueur
            ba_croquis
            bas_reference
            co_id
            co_libelle
            fa_id
            fa_libelle

            cog_id
            cog_libelle

            ba_prix_fourniture
            ba_prix
            ba_prix_majoration
            ba_largeur
            ba_chutes

            ba_maxi
          }
        }
      }

      astral_face: file(relativePath: { eq: "astral-face.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      allMysqlAccessoires {
        edges {
          node {
            pa_id
            pat_libelle
            pat_id
            pa_libelle
            pa_description
            pa_reference

            mysqlImages {
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `)

  const identifiants = useSelector(s => s.identifiants).identifiants
  const cart = useSelector(s => (identifiants !== null && identifiants.mode ? s.resellerCart : s.cart))
  const total = cart.products.reduce((acc, currrent) => acc + currrent.price * currrent.qty, 0)
  const dispatch = useDispatch()
  const handleRemove = id_product => {
    dispatch(
      removeProduct({
        _id: id_product,
      })
    )
    if (props.removeProduct) {
      props.removeProduct()
    }

    if (total === 0) {
      if (typeof window !== "undefined") {
        navigate(`/`)
      }
      return null
    }
  }

  const handleUpdate = (evt, _id) => {
    evt.preventDefault()
    if (evt.target.value != "") {
      const product = cart.products.filter(u => u._id === _id)[0]

      var qty = 1
      if (evt.target.value == "" || evt.target.value < 1) {
        qty = 1
      } else {
        qty = parseInt(evt.target.value)
      }

      dispatch(
        updateProduct({
          ...product,
          _id,
          qty: qty,
        })
      )
    }
  }

  if (parseFloat(cart.products.reduce((acc, currrent) => acc + currrent.price * currrent.qty, 0)) === 0) {
    if (typeof window !== "undefined") {
      navigate(`/`)
    }
    return null
  }

  let only_ht = false
  const invoice_addresse =
    cart.invoiceAddress && identifiants && identifiants.addresses && identifiants.addresses.find(e => e.id_address == cart.invoiceAddress)

  if (invoice_addresse && (parseInt(invoice_addresse.id_zone) == 7 || (parseInt(invoice_addresse.id_zone) == 1 && invoice_addresse.ntva !== ""))) {
    //UE société=exonéré TVA /  hors UE société=exonéré TVA / hors UE particulier=exonéré TVA
    only_ht = true
  }
  return (
    <>
      <div className="columns has-text-weight-bold has-text-centered is-hidden-touch" style={{ background: "#FBFBFB" }}>
        <div className="panier header column is-4">Produit</div>
        <div style={{ borderLeft: "0" }} className={`panier header column is-${props.showQuantite ? 4 : 5}`}>
          Description
        </div>
        <div style={{ borderLeft: "0" }} className="panier header column is-1">
          Prix unitaire (HT)
        </div>
        <div style={{ borderLeft: "0" }} className="panier header column is-1">
          Qté
        </div>
        {props.showQuantite ? <div style={{ borderLeft: "0" }} className="panier header column is-1"></div> : null}
        <div style={{ borderLeft: "0" }} className="panier header column is-1">
          Total (HT)
        </div>
      </div>

      {cart
        ? cart.products
            .sort((a, b) => a.position - b.position)
            .map(produit => (
              <div key={produit._id} className="columns is-desktop panier line">
                <div
                  className={
                    data.allMysqlNewProduit.edges.filter(u => u.node.ba_id == produit.id_product).length > 0 &&
                    data.allMysqlNewProduit.edges.filter(u => u.node.ba_id == produit.id_product)[0].node.cog_id === 3
                      ? "imageProduct backgroundPouBlanc column is-4"
                      : "is-4-desktop column"
                  }
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column-reverse",
                    minHeight: 100,
                    height: produit.type == "echantillon" ? 100 : "auto",
                  }}
                >
                  {produit.type == "passe_partout" ? (
                    <ImgPasse style={{ width: produit.type == "echantillon" ? 100 : 200, height: 200 }} id={produit.product.pa_id} />
                  ) : produit.type == "accessoire" ? (
                    data.allMysqlAccessoires.edges.filter(u => u.node.pa_id == produit.id_product).length > 0 ? (
                      <Img
                        style={{ width: 200, height: 200 }}
                        fluid={data.allMysqlAccessoires.edges.filter(u => u.node.pa_id == produit.id_product)[0].node.mysqlImages[0].childImageSharp.fluid}
                      />
                    ) : (
                      <p style={{ color: "red" }}>Erreur supprimer le produit !</p>
                    )
                  ) : data.allMysqlNewProduit.edges.filter(u => u.node.ba_id == produit.id_product).length > 0 ? (
                    produit.type == "echantillon" ? (
                      <ImgBaguette
                        className="couleurShadow"
                        type="coin"
                        style={{ height: "70%", width: "70%", margin: "0 2rem" }}
                        alt={"échantillon"}
                        id={produit.ba_id}
                      />
                    ) : produit.service === "precoupe" ? (
                      <PrevisuPreCoupe
                        largeurFixe={200}
                        largeurCadre={40}
                        hauteurCadre={30}
                        idProduct={produit.ba_id}
                        classNamePlus={"cadreFocus"}
                        component={"productPanier"}
                        backgroundPouBlanc={
                          data.allMysqlNewProduit.edges.filter(u => u.node.ba_id == produit.id_product)[0].node.cog_id === 3 ? "#9F8F7E" : "white"
                        }
                      />
                    ) : produit.service === "astral" ? (
                      <Img style={{ width: 200, height: 200 }} fluid={data.astral_face.childImageSharp.fluid} alt="Cadre astral" />
                    ) : (
                      <PrevisuCadreComplet
                        hauteur={data.allMysqlNewProduit.edges.filter(u => u.node.ba_id == produit.id_product)[0].node.ba_largeur}
                        tailleMax={data.allMysqlNewProduit.edges.filter(u => u.node.ba_id == produit.id_product)[0].node.ba_maxi}
                        largeurFixe={250}
                        hauteurFixe={250}
                        doublo={typeof produit.pageProduit !== "undefined" ? produit.pageProduit.doublo : null}
                        largeurCadre={produit.largeur}
                        hauteurCadre={produit.hauteur}
                        couleurPassePartout={"#cdae83"}
                        service={produit.service}
                        idProduct={produit.ba_id}
                        classNamePlus={"cadreFocus"}
                      />
                    )
                  ) : (
                    <p style={{ color: "red" }}>Erreur supprimer le produit !</p>
                  )}
                </div>
                <div className={`column is-${props.showQuantite ? 4 : 5}-desktop`}>
                  <p
                    key={`body`}
                    id="___gatsby"
                    dangerouslySetInnerHTML={{
                      __html: produit.description,
                    }}
                  />
                </div>
                <div className="column is-1-desktop fullcenter">
                  <span className="is-hidden-desktop">Prix unitaire :&nbsp;</span>
                  {new Intl.NumberFormat("fr-FR", {
                    style: "currency",
                    currency: "EUR",
                  }).format(parseFloat(produit.price / 1.2) * (identifiants !== null && identifiants.mode ? 1 + identifiants.margin / 100 : 1))}
                </div>
                <div className="column is-1-desktop fullcenter">
                  <span className="is-hidden-desktop">Quantité :&nbsp;</span>
                  {props.showQuantite ? (
                    <input
                      type="number"
                      style={{ marginLeft: "1rem" }}
                      id="quantite"
                      name="quantite"
                      value={produit.qty}
                      onChange={e => handleUpdate(e, produit._id)}
                      className="input input-number"
                      min={produit.step}
                      required
                    />
                  ) : (
                    produit.qty
                  )}
                  &nbsp;
                </div>
                {props.showQuantite ? (
                  <div className="column is-1-desktop fullcenter">
                    <button className="button is-danger" onClick={() => handleRemove(produit._id, produit.price * produit.qty)}>
                      Supprimer
                    </button>
                  </div>
                ) : null}
                <div className="column is-1-desktop fullcenter">
                  <span className="is-hidden-desktop">Total produit HT :&nbsp;</span>
                  {new Intl.NumberFormat("fr-FR", {
                    style: "currency",
                    currency: "EUR",
                  }).format(parseFloat((produit.price * produit.qty) / 1.2) * (identifiants !== null && identifiants.mode ? 1 + identifiants.margin / 100 : 1))}
                </div>
              </div>
            ))
        : null}
      <div className="columns">
        <div className="column is-8">
          {!props.boolMinCommande && identifiants !== null && !identifiants.mode && (
            <>
              <div className="notification is-warning is-warningYellow">
                <p className="has-text-centered">
                  Vous êtes en dessous du minimum de commande de {props.min_commande / 1.2} € (HT hors frais de port et emballage)
                </p>
                <p className="has-text-centered">Veuillez rajouter des produits pour atteindre le minimum de commande</p>
              </div>
              <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                <Link className="button is-secondary" to="/cadres" style={{ marginTop: "1rem" }}>
                  Continuer mes achats
                </Link>
              </div>
            </>
          )}
        </div>
        <div className="column is-4">
          <table>
            <tbody>
              <tr>
                <td className="tfooter">
                  <strong>Sous-total</strong>
                </td>
                <td className="tfooter">
                  {new Intl.NumberFormat("fr-FR", {
                    style: "currency",
                    currency: "EUR",
                  }).format(
                    parseFloat(cart.products.reduce((acc, currrent) => acc + (currrent.price / 1.2) * currrent.qty, 0)) *
                      (identifiants !== null && identifiants.mode ? 1 + identifiants.margin / 100 : 1)
                  )}
                  HT
                </td>
              </tr>
              {identifiants !== null && !identifiants.mode ? (
                <>
                  <tr>
                    <td className="tfooter">
                      <strong>Frais de port</strong>
                    </td>
                    <td className="tfooter">
                      {invoice_addresse && parseInt(invoice_addresse.id_zone) !== 9 && cart.fraisPort === 0
                        ? "Montant à définir"
                        : new Intl.NumberFormat("fr-FR", {
                            style: "currency",
                            currency: "EUR",
                          }).format(cart.fraisPort / 1.2)}{" "}
                      HT
                    </td>
                  </tr>

                  <tr>
                    <td className="tfooter">
                      <strong>Total</strong>
                    </td>
                    <td className="tfooter">
                      <strong>
                        {new Intl.NumberFormat("fr-FR", {
                          style: "currency",
                          currency: "EUR",
                        }).format(
                          parseFloat(cart.products.reduce((acc, currrent) => acc + (currrent.price / 1.2) * currrent.qty, 0) + cart.fraisPort / 1.2)
                        )}{" "}
                        HT
                      </strong>
                    </td>
                  </tr>
                  {!only_ht ? (
                    <tr>
                      <td className="tfooter">
                        <strong>Total TVA</strong>
                      </td>
                      <td className="tfooter">
                        <strong>
                          {new Intl.NumberFormat("fr-FR", {
                            style: "currency",
                            currency: "EUR",
                          }).format(
                            parseFloat(
                              cart.products.reduce((acc, currrent) => acc + currrent.price * currrent.qty, 0) +
                                cart.fraisPort -
                                (cart.products.reduce((acc, currrent) => acc + (currrent.price / 1.2) * currrent.qty, 0) + cart.fraisPort / 1.2)
                            )
                          )}
                        </strong>
                      </td>
                    </tr>
                  ) : null}

                  {!only_ht ? (
                    <tr>
                      <td className="tfooter">
                        <strong>Total</strong>
                      </td>
                      <td className="tfooter">
                        <strong>
                          {new Intl.NumberFormat("fr-FR", {
                            style: "currency",
                            currency: "EUR",
                          }).format(parseFloat(cart.products.reduce((acc, currrent) => acc + currrent.price * currrent.qty, 0) + cart.fraisPort))}{" "}
                          TTC
                        </strong>
                      </td>
                    </tr>
                  ) : null}
                </>
              ) : (
                ""
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

CartProducts.propTypes = {
  location: PropTypes.object,
  removeProduct: PropTypes.func,
  updateQtyProduct: PropTypes.func,
  productNotExistInRedux: PropTypes.func,
  showQuantite: PropTypes.bool,
  fraisLivraison: PropTypes.number,
  boolMinCommande: PropTypes.bool,
  min_commande: PropTypes.number,
}

export default CartProducts
